import React from 'react';
import './Home.css';

const Home = () => {

  const products = [
    {
      id: 'powertools',
      title: 'Powertools',
      mainImage: '/images/products/accessories.jpg',
      additionalImages: [
        {
          image: '/images/products/accessories.jpg',
          label: 'Powertools 1'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Powertools 2'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Powertools 3'
        }
      ]
    },
    {
      id: 'machine',
      title: 'Machine',
      mainImage: '/images/products/accessories.jpg',
      additionalImages: [
        {
          image: '/images/products/accessories.jpg',
          label: 'Machine 1'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Machine 2'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Machine 3'
        }
      ]
    },
    {
      id: 'accessories',
      title: 'Accessories',
      mainImage: '/images/products/accessories.jpg',
      additionalImages: [
        {
          image: '/images/products/accessories.jpg',
          label: 'Accessories 1'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Accessories 2'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Accessories 3'
        }
      ]
    },
    {
      id:'spares',
      title: 'Spares',
      mainImage: '/images/products/accessories.jpg',
      additionalImages: [
        {
          image: '/images/products/accessories.jpg',
          label: 'Spares 1'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Spares 2'
        },
        {
          image: '/images/products/accessories.jpg',
          label: 'Spares 3'
        }
      ]
    }
    // ... add other products similarly
  ];

  const services = [
    {
      id: 'spare',
      title: 'Spare Parts',
      mainImage: '/images/products/accessories.jpg',
      hoverImages: [
        '/images/products/accessories.jpg',
        '/images/products/accessories.jpg',
        '/images/products/accessories.jpg'
      ]
    },
    {
      id: 'service',
      title: 'Services',
      mainImage: '/images/products/accessories.jpg',
      hoverImages: [
        '/images/products/accessories.jpg',
        '/images/products/accessories.jpg',
        '/images/products/accessories.jpg'
      ]
    }
  ];

  return (
    <div className='home-wrapper'>
      <div className="home-container">
        <div className='company-info'>
          <h1>Welcome to Parts Xpress</h1>
          <p className="company-description">
            We are a leading provider of high-quality car parts, specializing in both passenger and commercial vehicles.
            With years of experience in the automotive industry, we pride ourselves on offering durable and reliable parts that meet
            the highest standards. Explore our extensive catalog to find everything you need to keep your vehicles running smoothly.
          </p>
        </div>
      </div>

      <div className="home-container">
        <div className='company-info'>
          <h1>Our Products</h1>
          <div className='home-card-container'>
            {products.map(product => (
              <div key={product.id} className='home-card'>
                <img 
                  src={product.mainImage} 
                  alt={product.title} 
                  className="home-card-image" 
                />
                <div className="additional-images">
                  {product.additionalImages.map((img, index) => (
                    <div key={index} className="image-wrapper">
                      <img src={img.image} alt={`${product.title} ${index + 1}`} />
                      <span className="image-label">{img.label}</span>
                    </div>
                  ))}
                </div>
                <p className="home-card-label">{product.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className='company-info'>
          <h1>Diesel Center</h1>

          <div className="services-container">
            {services.map((service) => (
              <div key={service.id} className="service-card">
                <div className="main-image-container">
                  <img
                    src={service.mainImage}
                    alt={service.title}
                    className="main-image"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h2>{service.title}</h2>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>

                <div className="hover-images">
                  {service.hoverImages.map((img, index) => (
                    <div key={index} className="hover-image-container">
                      <img
                        src={img}
                        alt={`${service.title} preview ${index + 1}`}
                        className="hover-image"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>



      <div className="home-container">
        <div className='company-info'>
          <h1>Why Choose Us?</h1>
          <div className='choose-us-box'>
            <div className='choose-us-card'>
              <img src="/images/icons/inventory.png" alt="Inventory Icon" className="choose-us-icon" />
              <h3>Extensive Inventory</h3>
              {/* <p>
                Choose from thousands of car parts for both passenger and commercial vehicles.
              </p> */}
            </div>
            <div className='choose-us-card'>
              <img src="/images/icons/trusted-quality.png" alt="Quality Icon" className="choose-us-icon" />
              <h3>Trusted Quality</h3>
              {/* <p>
                Our parts are sourced from leading manufacturers, ensuring reliability and performance.
              </p> */}
            </div>
            <div className='choose-us-card'>
              <img src="/images/icons/customer-support.png" alt="Support Icon" className="choose-us-icon" />
              <h3>Customer Support</h3>
              {/* <p>
                Get expert assistance from our knowledgeable team to find the right part.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
