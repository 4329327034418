import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import CarPartsList from './pages/CarPartsList';
import PartDetails from './pages/PartDetails';
import Header from './components/Header'; // Import the Header component
import './App.css';
import Footer from './components/Footer';
import PowertoolsAccessories from './pages/PowerToolsAccessories';
import DieselCenter from './pages/DieselCenter/DieselCenter';

function App() {
  return (
    <Router>
      <div className="AppContainer">
        <Header /> {/* Add Header component here */}
        <main className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/diesel-center" element={<DieselCenter />} />
            <Route path="/diesel-center/:category" element={<DieselCenter />} />
            <Route path="/powertools-accessories" element={<PowertoolsAccessories />} />
            <Route path="/powertools-accessories/:category" element={<PowertoolsAccessories />} />
            <Route path="/parts-detail/:id" element={<PartDetails />} />
            <Route path="/parts" element={<CarPartsList />} />
            <Route path="/parts/:category" element={<CarPartsList />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
