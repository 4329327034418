// src/pages/DieselCenter/DieselCenter.js
import './DieselCenter.css';
import { useState } from 'react';

// src/pages/DieselCenter/DieselCenter.js
const DieselCenter = () => {

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const menuItems = {
    A: ['Nozzles', 'Plungers', 'Pumps'],
    B: ['Injector', 'Pump',]
  };


  return (
    <div className='dc-wrapper'>
      <div className="dc-container">
        <div className='dc-title'>
          <h1>DieselCenter</h1>
        </div>
      </div>
      <div className="dropdown-container">

      <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {/* Option A with dropdown */}
        <div
          onMouseEnter={() => setActiveDropdown('A')}
          onMouseLeave={() => setActiveDropdown(null)}
        >
          <div className="option-button">
            Spares
          </div>
          
          {activeDropdown === 'A' && (
            <div className="dropdown-content">
              {menuItems.A.map((item, index) => (
                <div key={index} className="dropdown-item">
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>

        <div
          onMouseEnter={() => setActiveDropdown('B')}
          onMouseLeave={() => setActiveDropdown(null)}
        >
        {/* Option B */}
        <div className="option-button">
          Services
        </div>
        {activeDropdown === 'B' && (
          <div className="dropdown-content">
            {menuItems.B.map((item, index) => (
              <div key={index} className="dropdown-item">
                {item}
              </div>
            ))}
          </div>
        )}
        </div>
      </div>
    </div>
  );
}

export default DieselCenter;