import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navRef = useRef(null);

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle individual dropdown
  const toggleDropdown = (dropdownName) => {
    console.warn('toggleDropdown', dropdownName, activeDropdown);
    if (window.innerWidth <= 768) {
      setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    } else {
      setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    }
  };

  // Close menu and dropdown when clicking a link
  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Reset states on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
      setActiveDropdown(null);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigateToHome = () => {
    navigate('/');
    handleLinkClick()
  };

  return (
    <>
      <div className="gradient" />
      <header className="header" ref={navRef}>
        {/* Logo Section */}
        <img className="logo" onClick={navigateToHome} src="./images/cropped-logo.png" alt="Company Logo" />
        <div className="company-name" onClick={navigateToHome}>
          <p>Parts Xpress</p>
        </div>

        {/* Hamburger Menu Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? '✖' : '☰'} {/* 'X' for close, '☰' for open */}
        </div>

        {/* Navigation Links (Slide-in Menu) */}
        <nav className={`header-nav-links ${isMenuOpen ? 'open' : ''}`}>
          {/* <div className="header-dropdown">
            <Link to="/" onClick={handleLinkClick}>Home</Link>
          </div> */}
          <div className="header-dropdown">
            <Link to="/diesel-center" onClick={() => {
              toggleDropdown('diesel')
              handleLinkClick()
            }}>Diesel Center</Link>
            <div className={`header-dropdown-content ${activeDropdown === 'diesel' || isMenuOpen ? 'show' : ''}`}>
              <Link to="/diesel-center/spares" onClick={handleLinkClick}>Spares</Link> {/* Dropdown item */}
              <Link to="/diesel-center/services" onClick={handleLinkClick}>Services</Link> {/* Dropdown item */}
            </div>
          </div>
          <div className="header-dropdown">
            <Link to="/powertools-accessories" onClick={() => {
              toggleDropdown('powertools')
              handleLinkClick()
            }}>Powertools & Machines</Link>
            <div className={`header-dropdown-content ${activeDropdown === 'powertools' || isMenuOpen ? 'show' : ''}`}>
              <Link to="/powertools-accessories/tools" onClick={handleLinkClick}>Tools</Link> {/* Dropdown item */}
              <Link to="/powertools-accessories/machines" onClick={handleLinkClick}>Machines</Link> {/* Dropdown item */}
              <Link to="/powertools-accessories/accessories" onClick={handleLinkClick}>Accessories</Link> {/* Dropdown item */}
            </div>
          </div>
          <div className="header-dropdown">
            <Link to="/parts" onClick={() => {
              toggleDropdown('parts')
              handleLinkClick()
            }}>Auto spare parts</Link>
            <div className={`header-dropdown-content ${activeDropdown === 'parts' || isMenuOpen ? 'show' : ''}`}>
              <Link to="/parts/commercial" onClick={handleLinkClick}>Commercial</Link> {/* Dropdown item */}
              <Link to="/parts/passenger" onClick={handleLinkClick}>Passenger</Link> {/* Dropdown item */}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
