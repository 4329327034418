import { useState } from 'react';
import './PowerToolsAccessories.css';
import { Link } from 'react-router-dom';

const PowertoolsAccessories = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = [
    {
      id: 'tools',
      name: 'Tools',
      items: [
        { id: 1, name: 'DeWalt DCD777C2 Drill', price: '$199.99', rating: 4.8, specs: '20V MAX, Brushless Motor', popularity: 95 },
        { id: 2, name: 'Milwaukee M18 Impact Driver', price: '$179.99', rating: 4.7, specs: '18V, 1800 in-lbs Torque', popularity: 88 },
        { id: 3, name: 'Makita GA4534 Grinder', price: '$149.99', rating: 4.6, specs: '4.5-inch, 11000 RPM', popularity: 82 }
      ]
    },
    {
      id: 'machines',
      name: 'Machines',
      items: [
        { id: 4, name: 'TEKTON 14-pc Wrench Set', price: '$89.99', rating: 4.9, specs: 'Chrome Vanadium Steel', popularity: 90 },
        { id: 5, name: 'iFixit Pro Tech Kit', price: '$49.99', rating: 4.7, specs: '64-bit Driver Kit', popularity: 85 },
        { id: 6, name: 'Estwing E3-16C Hammer', price: '$29.99', rating: 4.5, specs: '16oz, Leather Grip', popularity: 80 }
      ]
    },
    {
      id: 'accessories',
      name: 'Accessories',
      items: [
        { id: 7, name: 'Bosch 50pc TiN Drill Set', price: '$39.99', rating: 4.8, specs: 'Titanium Nitride Coating', popularity: 92 },
        { id: 8, name: '3M Virtua CCS Glasses', price: '$19.99', rating: 4.6, specs: 'Anti-Fog, ANSI Z87.1', popularity: 87 },
        { id: 9, name: 'Mechanix M-Pact Gloves', price: '$24.99', rating: 4.5, specs: 'TPR Protection, D3O', popularity: 84 }
      ]
    }
  ];

  const allItems = categories.flatMap(cat => cat.items);

  const filteredItems = allItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (activeCategory === 'all' || categories.find(cat => cat.id === activeCategory)?.items.includes(item))
  );

  const RatingStars = ({ rating }) => {
    return (
      <div className="p-and-m-rating">
        <span className="p-and-m-stars">
          {'★'.repeat(Math.floor(rating))}
          {'☆'.repeat(5 - Math.floor(rating))}
        </span>
        <span className="p-and-m-rating-number">({rating})</span>
      </div>
    );
  };

  return (
    <div className='power-tools-accessories-wrapper'>
      <div className="power-tools-accessories-container">
        <div>
          <h1>Powertools & Machines</h1>
        </div>
      </div>

      <div className="power-tools-accessories-container">
        <div className="p-and-m-search">
          <span className="p-and-m-search-icon"><img src='images/icons/search.png' alt='search' style={{ width: '22px', height: '22px' }}></img></span>
          <input
            type="text"
            placeholder="Search tools and machines..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="power-tools-accessories-container">
        <div className="p-and-m-categories">
          <div className="p-and-m-category-buttons">
            <button
              className={activeCategory === 'all' ? 'active' : ''}
              onClick={() => setActiveCategory('all')}
            >
              All Categories
            </button>
            {categories.map(category => (
              <button
                key={category.id}
                className={activeCategory === category.id ? 'active' : ''}
                onClick={() => setActiveCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="power-tools-accessories-container"> */}
      <div className="p-and-m-items">
        {filteredItems.map(item => (
          <div key={item.id} className="p-and-m-card">
            <div className="p-and-m-card-header">
              <h3>{item.name}</h3>
              <RatingStars rating={item.rating} />
            </div>
            <p className="p-and-m-specs">{item.specs}</p>
            <div className="p-and-m-card-footer">
              <span className="p-and-m-price">{item.price}</span>
              <Link to={`/powertools-accessories/${item.id}`} className="p-and-m-arrow">→</Link>
            </div>
          </div>
        ))}
      </div>
      {/* </div> */}

    </div>
  );
}

export default PowertoolsAccessories;