import React from 'react';
import { useParams } from 'react-router-dom';
import { partsData } from '../data/car-parts';

const PartDetails = () => {
  const { id } = useParams();
  const part = partsData.find(p => p.id === parseInt(id));

  if (!part) return <p>Part not found.</p>;

  return (
    <div className="part-details">
      <img src={part.img} alt={part.title} className="part-image" />
      <h2>{part.title}</h2>
      <p>{part.description}</p>
    </div>
  );
};

export default PartDetails;
