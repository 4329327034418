import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ part }) => {
  return (
    <div className="card">
      <div className="card-content">
        <div className="card-header">
          <h3 className="part-number">{part.OE_PART_NUMBER}</h3>
          <p className="bosch-number">Bosch: {part.BOSCH_PART_NO}</p>
        </div>
        
        <div className="card-details">
          <div className="detail-row">
            <span className="label">Make:</span>
            <span className="value">{part.MAKE || 'N/A'}</span>
          </div>
          
          <div className="detail-row">
            <span className="label">External Part:</span>
            <span className="value">{part.EXTERNAL_PART_NUMBER || 'N/A'}</span>
          </div>
          
          <div className="detail-row">
            <span className="label">External Make:</span>
            <span className="value">{part.EXTERNAL_MAKE || 'N/A'}</span>
          </div>

          <div className="detail-row">
            <span className="label">Vehicle:</span>
            <span className="value vehicle-text">{part.VEHICLE_APPLICATION || 'N/A'}</span>
          </div>
        </div>
      </div>

      <Link to={`/parts-detail/${part.id}`} className="read-more">
        View Details
      </Link>
    </div>
  );
};

export default Card;