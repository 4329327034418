export const partsData = [
  { id: 1, title: 'Engine', img: 'engine.jpg', description: 'The heart of the vehicle, responsible for power generation.', category: 'passenger' },
  { id: 2, title: 'Tire', img: 'tire.jpg', description: 'Provides traction and support for the vehicle.', category: 'passenger' },
  { id: 3, title: 'Brake Pads', img: 'brake-pads.jpg', description: 'Essential for slowing down and stopping the vehicle.', category: 'passenger' },
  { id: 4, title: 'Battery', img: 'battery.jpg', description: 'Stores energy for starting the engine and powering accessories.', category: 'passenger' },
  { id: 5, title: 'Radiator', img: 'radiator.jpg', description: 'Cools the engine to prevent overheating.', category: 'passenger' },
  { id: 6, title: 'Alternator', img: 'alternator.jpg', description: 'Charges the battery and powers the electrical system.', category: 'passenger' },
  { id: 7, title: 'Clutch', img: 'clutch.jpg', description: 'Facilitates gear shifting in manual transmissions.', category: 'passenger' },
  { id: 8, title: 'Air Filter', img: 'air-filter.jpg', description: 'Cleans the air entering the engine for better performance.', category: 'passenger' },
  { id: 9, title: 'Fuel Pump', img: 'fuel-pump.jpg', description: 'Delivers fuel to the engine.', category: 'passenger' },
  { id: 10, title: 'Headlight', img: 'headlight.jpg', description: 'Provides illumination for nighttime driving.', category: 'passenger' },
  { id: 11, title: 'Tail Light', img: 'tail-light.jpg', description: 'Indicates the vehicle\'s presence to other drivers.', category: 'passenger' },
  { id: 12, title: 'Exhaust System', img: 'exhaust-system.jpg', description: 'Directs exhaust gases away from the vehicle.', category: 'passenger' },
  { id: 13, title: 'Suspension', img: 'suspension.jpg', description: 'Absorbs bumps and vibrations for a smoother ride.', category: 'passenger' },
  { id: 14, title: 'Steering Wheel', img: 'steering-wheel.jpg', description: 'Controls the direction of the vehicle.', category: 'passenger' },
  { id: 15, title: 'Brake Disc', img: 'brake-disc.jpg', description: 'Converts kinetic energy into heat to slow down the vehicle.', category: 'passenger' },
  { id: 16, title: 'Transmission', img: 'transmission.jpg', description: 'Transfers power from the engine to the wheels.', category: 'passenger' },
  { id: 17, title: 'Windshield', img: 'windshield.jpg', description: 'Protects occupants from wind and debris.', category: 'passenger' },
  { id: 18, title: 'Mirror', img: 'mirror.jpg', description: 'Provides visibility of the vehicle\'s surroundings.', category: 'passenger' },
  { id: 19, title: 'Bumper', img: 'bumper.jpg', description: 'Protects the vehicle from minor impacts.', category: 'passenger' },
  { id: 20, title: 'Door Handle', img: 'door-handle.jpg', description: 'Facilitates entry and exit from the vehicle.', category: 'passenger' },
  { id: 21, title: 'Truck Bed', img: 'truck-bed.jpg', description: 'Provides a platform for carrying heavy loads.', category: 'commercial' },
  { id: 22, title: 'Trailer Hitch', img: 'trailer-hitch.jpg', description: 'Enables towing of trailers and heavy equipment.', category: 'commercial' },
  { id: 23, title: 'Heavy-Duty Battery', img: 'heavy-duty-battery.jpg', description: 'Designed for high-performance and heavy-duty applications.', category: 'commercial' },
  { id: 24, title: 'Commercial Tire', img: 'commercial-tire.jpg', description: 'Built for heavy loads and frequent use.', category: 'commercial' },
  { id: 25, title: 'Air Brake System', img: 'air-brake-system.jpg', description: 'Essential for safe braking in heavy vehicles.', category: 'commercial' },
  { id: 26, title: 'Transmission Cooler', img: 'transmission-cooler.jpg', description: 'Prevents overheating of the transmission in heavy-duty applications.', category: 'commercial' },
  { id: 27, title: 'Suspension Upgrade', img: 'suspension-upgrade.jpg', description: 'Enhances the suspension system for heavy loads and towing.', category: 'commercial' },
  { id: 28, title: 'Heavy-Duty Alternator', img: 'heavy-duty-alternator.jpg', description: 'Provides high output for heavy-duty electrical systems.', category: 'commercial' },
  { id: 29, title: 'Commercial Radiator', img: 'commercial-radiator.jpg', description: 'Designed for high-performance cooling in heavy-duty applications.', category: 'commercial' },
  { id: 30, title: 'Exhaust Brake', img: 'exhaust-brake.jpg', description: 'Assists in slowing down heavy vehicles.', category: 'commercial' },
  { id: 31, title: 'Commercial Air Filter', img: 'commercial-air-filter.jpg', description: 'Cleans the air entering the engine for better performance in heavy-duty applications.', category: 'commercial' },
  { id: 32, title: 'Fuel Tank', img: 'fuel-tank.jpg', description: 'Stores fuel for extended periods of operation.', category: 'commercial' },
  { id: 33, title: 'Commercial Headlight', img: 'commercial-headlight.jpg', description: 'Provides high-intensity illumination for nighttime driving in heavy vehicles.', category: 'commercial' },
  { id: 34, title: 'Commercial Tail Light', img: 'commercial-tail-light.jpg', description: 'Indicates the vehicle\'s presence to other drivers in heavy-duty applications.', category: 'commercial' },
  { id: 35, title: 'Commercial Windshield', img: 'commercial-windshield.jpg', description: 'Protects occupants from wind and debris in heavy vehicles.', category: 'commercial' },
  { id: 36, title: 'Commercial Mirror', img: 'commercial-mirror.jpg', description: 'Provides visibility of the vehicle\'s surroundings in heavy-duty applications.', category: 'commercial' },
  { id: 37, title: 'Commercial Bumper', img: 'commercial-bumper.jpg', description: 'Protects the vehicle from minor impacts in heavy-duty applications.', category: 'commercial' },
  { id: 38, title: 'Commercial Door Handle', img: 'commercial-door-handle.jpg', description: 'Facilitates entry and exit from the vehicle in heavy-duty applications.', category: 'commercial' },
  { id: 39, title: 'Commercial Steering Wheel', img: 'commercial-steering-wheel.jpg', description: 'Controls the direction of the vehicle in heavy-duty applications.', category: 'commercial' },
  { id: 40, title: 'Commercial Brake Disc', img: 'commercial-brake-disc.jpg', description: 'Converts kinetic energy into heat to slow down the vehicle in heavy-duty applications.', category: 'commercial' },
];