// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <h2>Contact Us</h2>
      <div className='footer-details'>
      <p><strong>Address:</strong> 123 Business St, City, Country</p>
      <p><strong>Phone:</strong> +123 456 7890</p>
      <p><strong>Email:</strong> contact@partsxpress.com</p>
      </div>

      <p className='rights-reserved'>© 2024 Parts Xpress. All rights reserved.</p>
    </footer>
  );
}

export default Footer;