import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import './CarPartsList.css';
import { partsData } from '../data/car-parts';
import { useParams } from 'react-router-dom';
import commercialData from '../data/commercial.json'

const getAllNestedValues = (obj) =>  {
  const values = Object.values(obj);
  const flattenedValues = [];
  
  values.forEach(value => {
      if (Array.isArray(value)) {
          // If value is an array, spread its elements
          flattenedValues.push(...value);
      } else {
          flattenedValues.push(value);
      }
  });
  
  return flattenedValues;
}

const data = getAllNestedValues(commercialData);

const CarPartsList = () => {
  // State for search query and filtered data
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredParts, setFilteredParts] = useState(data);

  const { category } = useParams(); // Get category from URL params

  useEffect(() => {
    // Filter parts based on category
    // const filtered = category 
    // ? partsData.filter(part => part.category === category) 
    // : partsData; // Show all parts if no category is provided
    // setFilteredParts(filtered);
    setCurrentPage(1); // Reset to first page when category changes
  }, [category]); // Run effect when category changes


  // Handle search input change
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query); // Update search query state

    // Filter parts based on search query, with checks to prevent undefined errors
    const filtered = partsData.filter(part =>
      (part.title && part.title.toLowerCase().includes(query)) ||
      (part.description && part.description.toLowerCase().includes(query))
    );
    setFilteredParts(filtered);
  };

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Number of items to display per page

  // Calculate the index of the last item on the current page
  const indexOfLastPart = currentPage * itemsPerPage;
  // Calculate the index of the first item on the current page
  const indexOfFirstPart = indexOfLastPart - itemsPerPage;
  // Get the current parts to display
  const currentParts = filteredParts.slice(indexOfFirstPart, indexOfLastPart);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const getTitleName = () => {
    if (category === 'passenger') {
      return 'Passenger Vehicles';
    }
    if (category === 'commercial') {
      return 'Commercial Vehicles';
    }

    return 'All Products';
  }

  return (
    <div className="car-parts-page">
      <h2>{getTitleName()}</h2>
      <div className="grid-container">
        {currentParts.map((part) => (
          <Card key={part.id} part={part} />
        ))}
      </div>
      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredParts.length / itemsPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CarPartsList;
